<div class="connected">
    <po-container p-no-shadow="true">
      <h4 class="connected__title">Conectado ao app <strong>{{appSelected}}</strong></h4>
      <p class="connected__msg">Utilize o <strong>alias</strong> abaixo para conectar ao servidor:</p>
      <h2 class="connected__alias">{{ alias }}</h2>

      <lottie-animation-view [options]="lottieConfig" [width]="300" [height]="300"
        (animCreated)="handleAnimation($event)">
      </lottie-animation-view>


      <div class="connected__container-button">
        <!-- <po-button p-type="primary" p-label="Terminar" (p-click)="logout()"></po-button> -->
      </div>
    </po-container>
  </div>
