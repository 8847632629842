import { Component, OnInit, Input, AfterViewInit, NgZone } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-connected',
  templateUrl: './connected.component.html',
  styleUrls: ['./connected.component.scss']
})
export class ConnectedComponent implements OnInit {
  // tslint:disable-next-line:no-input-rename
  @Input('alias') alias;
  @Input('appSelected') appSelected;

  public lottieConfig: {};
  private anim: any;
  private animationSpeed = 1;

  constructor(private zone: NgZone, private router: Router) {
    this.lottieConfig = {
      path: 'assets/connected.json',
      renderer: 'svg',
      autoplay: true,
      loop: true
    };

  }
  handleAnimation(anim: any) {
       this.anim = anim;

       this.zone.runOutsideAngular(() => {
        this.lottieConfig = {
          path: 'assets/connected.json',
          renderer: 'canvas',
          autoplay: true,
          loop: true
        };
       });

  }
  ngOnInit() {

  }
  public logout() {
    // localStorage.removeItem('userToken');
    // tslint:disable-next-line:semicolon
    this.router.navigate(['/'])
  }


}
