import { AppService } from './../../app.service';
import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PoNotificationService } from '@po-ui/ng-components';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-connect-protheus',
  templateUrl: './connect-protheus.component.html',
  styleUrls: ['./connect-protheus.component.scss']
})
export class ConnectProtheusComponent implements OnInit {
  public buttonLoading = false;
  public connectProtheusForm: FormGroup;

  constructor(
    private http: HttpClient,
    private formBuilder: FormBuilder,
    public poNotification: PoNotificationService,
    private appService: AppService) { }
  @Output() statusConnection = new EventEmitter();

  ngOnInit() {
    this.connectProtheusForm = this.formBuilder.group({
      type: ['http', [Validators.required]],
      ip: ['localhost', [Validators.required]],
      port: ['8787', [Validators.required]],
      url: ['rest', [Validators.required]]
    });
  }

  public testConnection(host = {}) {
    this.buttonLoading = true;
    const hostFormatted = this.decodeHost(host);
    this.appService.setDataProtheus(hostFormatted);
    this.http.get(hostFormatted)
      .pipe(
        finalize(() => this.buttonLoading = false),
      )
      .subscribe(() => {
        this.poNotification.success('Rest conectado com sucesso!');

        this.statusConnection.emit({ status: true });

      }, err => {
        if (err.status === 200) {
          this.poNotification.success('Rest conectado com sucesso!');
          this.statusConnection.emit({ status: true });

        } else {
          this.poNotification.error('Falha ao conectar com o Rest.');
          this.statusConnection.emit({ status: false });
        }
      });
  }

  decodeHost(host): string {

    if (host) {
      if (host.ip.indexOf('://')) {
        const addr = host.ip.split('://').reverse();
        host.ip = addr[0];
      }
      const currentPort = host.port ? ':' + host.port : '';
      const currentEndPoint = host.url ? '/' + host.url : '';
      return (host.type + '://' + host.ip + currentPort + currentEndPoint);
    }
  }

}
