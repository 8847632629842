import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
const APP_API = environment.api;


@Injectable({
  providedIn: 'root'
})
export class HttpService {

  constructor(private http: HttpClient) { }

  get(endpoint) {
    return this.http.get(`${APP_API}/${endpoint}`);
  }

  post(endpoint, body = {}) {
    return this.http.post(`${APP_API}/${endpoint}`, body);
  }

}
