import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { ConnectService } from '../connect.service';
import { FormBuilder, FormArray, FormGroup, Validators, FormControl } from '@angular/forms';
import { finalize, timeout } from 'rxjs/operators';
import * as _ from 'lodash';

@Component({
  selector: 'app-connect-server',
  templateUrl: './connect-server.component.html',
  styleUrls: ['./connect-server.component.scss']
})
export class ConnectServerComponent implements OnInit {

  @Output() statusServerConnection = new EventEmitter();

  public appsOptions: Array<{ value: string, label: string, params?: [] }> = [{ value: '', label: '' }];
  public params: Array<{ value: string, key: string }> = [];
  public showParams = false;
  public connectForm: FormGroup;
  public paramsForm: FormArray;
  public alias: string;
  public isMpNotification = false;
  public buttonLoading = false;
  public appDisableControl = true;
  public appDisableControlText = 'Carregando...';
  public selectedApp: string;
  public backOfficeApps = [
    "Legal Process",
    "Legal Task",
    "Meu Posto de Trabalho",
    "Meu Protheus",
    "Meus Ativos Fixos",
    "Meus Contratos",
    "Minha Gestão de Postos",
    "Minha Prestação de Contas"
  ]

  constructor(
    private connectService: ConnectService,
    private formBuilder: FormBuilder
  ) {

  }

  ngOnInit() {
    this.createForm();
    this.getApps();
  }

  private createForm() {
    this.connectForm = this.formBuilder.group({
      app: ['', Validators.required],
      params: this.formBuilder.array([])
    });
  }

  private createParams(key): FormGroup {
    let frm = this.formBuilder.group({
      [key]: ['', Validators.required],
    });
    return frm;
  }

  private getApps() {
    this.connectService.appsList()
    .pipe(
      timeout(10000),
    )

    .subscribe((response: { apps: [] }) => {
      // tslint:disable-next-line:no-string-literal
      let apps = response ? response['apps'] : [];
      apps = apps.map((app) => {
        // tslint:disable-next-line:no-string-literal
        return { value: app['_id'], label: app['name'], params: app['params'] };
      });

      let backOfficeApps = [];

      this.backOfficeApps.map(app => {
        apps.map(res => {
          if(app === res.label) {
            backOfficeApps.push(res);
          }
        })
      })

      apps = backOfficeApps;
      this.appsOptions = _.orderBy(apps, ['label'], ['asc']);

      this.appDisableControl = false;
      this.appDisableControlText = "";

    }, err => {
      this.appDisableControlText = "Houve um erro, tente novamente.";
    });
  }

  public appSelected(value) {
    if (value === '5a3908248e1d980001a7f60a') {
      this.isMpNotification = true;
    } else {
      this.isMpNotification = false;
    }
    const app = this.appsOptions.find(appFind => appFind.value === value);
    this.selectedApp = app.label;
    this.params = app.params ? app.params : [];
    this.showFieldsParams(this.params);
    this.createParamsFormgroup(this.params);
  }

  private createParamsFormgroup(params: Array<{}>): void {

    this.paramsForm = this.connectForm.get('params') as FormArray;

    if (this.paramsForm.length > 0) {
      this.paramsForm.clear();
    }

    params.map(param => {
      // tslint:disable-next-line:no-string-literal
      this.paramsForm.push(this.createParams(param['key']));

    });


  }
  private showFieldsParams(params): void {
    this.showParams = params ? true : false;
  }

  public connect() {

    this.buttonLoading = true;
    this.connectService.connectToServer(this.connectForm.value)
      .pipe(
        finalize(() => this.buttonLoading = false),
      )
      .subscribe((data) => {
        // tslint:disable-next-line:no-string-literal
        this.alias = `${data['prefix']} ${data['sufix']}`;
        this.statusServerConnection.emit({ status: true, alias: this.alias, app: this.selectedApp });
      });
  }
}
