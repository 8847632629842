
<po-page-default>

  <po-stepper p-orientation="vertical" p-step-size="200" #stepper>
    <po-step p-label="Rest Protheus" [p-can-active-next-step]="canActiveAppLinkerStep.bind(this, protheusForm.connectProtheusForm)">
      <app-connect-protheus #protheusForm (statusConnection)="reciverProtheusStatus($event)" ></app-connect-protheus>
    </po-step>
    <po-step p-label="App Linker" [p-can-active-next-step]="canActiveStartStep.bind(this, serverForm.connectForm)">
      <app-connect-server #serverForm (statusServerConnection)="reciverServerStatus($event)" ></app-connect-server>
    </po-step>
    <po-step p-label="Start">
        <app-connected [alias]="alias" [appSelected]="appSelected" ></app-connected>
    </po-step>

  </po-stepper>


</po-page-default>
