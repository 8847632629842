import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { finalize } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '../../environments/environment';
import { PoPageLoginLiterals } from '@po-ui/ng-templates';
import { PoNotificationService, PoLanguage } from '@po-ui/ng-components';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  public loading = false;
  public customLiterals: PoPageLoginLiterals = {
    loginPlaceholder: 'Insira seu e-mail',
    passwordPlaceholder: 'Insira sua senha',
    submitLabel: 'Acessar sistema',
    loginHint: 'O usuário necessita ter o cadastro aprovado, caso não tenha recebido a aprovação entre em contato com o e-mail: protheus.mobile@totvs.com.br'
  };
  private readonly URL = environment.api + '/auth/login';
  languages: Array<PoLanguage> = [
    { language: 'pt', description: 'Português' }
  ]

  constructor(
    private router: Router,
    private http: HttpClient,
    public poNotification: PoNotificationService,
    public activatedRoute: ActivatedRoute
  ) {
    this.activatedRoute.queryParams.subscribe(params => {
      if (params.isActive) {
        this.poNotification.success({ message: 'Usuário validado com sucesso.' })
      }
    });
  }

  ngOnInit() {
  }

  loginSubmit(event) {

    this.loading = true;

    const body = {
      user: event.login,
      password: event.password
    };

    this.http.post(this.URL, body)
      .pipe(finalize(() => {
        this.loading = false;
      }))
      .subscribe((res: any) => {
        localStorage.setItem('userToken', res.token);
        this.router.navigate(['/'], { replaceUrl: true });
      }, error => {
        //retorno de mensagens de erro login
        this.poNotification.error({ message: error['error'].error });
      });
  }

}
