import { Component, OnInit, ɵConsole, ViewChild } from '@angular/core';
import { ConnectService } from './connect.service';
import { FormGroup, FormBuilder, FormArray, NgForm } from '@angular/forms';
import { PoModalComponent, PoStepperComponent, PoStepComponent, PoMenuItem, PoToolbarAction } from '@po-ui/ng-components';
import { Router } from '@angular/router';

@Component({
  selector: 'app-connect',
  templateUrl: './connect.component.html',
})
export class ConnectComponent implements OnInit {
  @ViewChild('stepper', { static: true }) stepperComponent: PoStepperComponent;

  private hasProtheusConnection = false;
  private hasServerConnection = false;
  public alias = '';
  public appSelected: string;

  actions: Array<PoToolbarAction> = [
    { icon: 'po-icon-exit', label: 'Sair', action: () => this.logout() },
  ];


  constructor(private router: Router) {

  }

  readonly menus: Array<PoMenuItem> = [
    { label: 'Sair', action: this.logout.bind(this) }
  ];

  private logout() {
    localStorage.removeItem('userToken');
    // tslint:disable-next-line:semicolon
    this.router.navigate(['login'])
  }

  private onClick(response?) {
    alert('Clicked in menu item');
  }

  ngOnInit() {
  }

  reciverProtheusStatus(response: {}) {
    // tslint:disable-next-line:no-string-literal
    this.hasProtheusConnection = response['status'];
    this.stepperComponent.next();
  }

  reciverServerStatus(response: {}) {
    // tslint:disable-next-line:no-string-literal
    this.hasServerConnection = response['status'];
    // tslint:disable-next-line:no-string-literal
    this.appSelected = response['app'];
    this.alias = response['alias'];
    this.stepperComponent.next();
  }

  public canActiveAppLinkerStep(form: NgForm) {
    return form.valid && this.hasProtheusConnection;
  }

  public canActiveStartStep(form: NgForm) {
    return form.valid && this.hasServerConnection;
  }

}
