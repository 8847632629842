
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

import { PoPageAction } from '@po-ui/ng-components';
import { PoNotificationService} from '@po-ui/ng-components';
import { FormBuilder, Validators, AbstractControl, FormGroup } from '@angular/forms';
import { HttpService } from '../providers/http.service';


@Component({
    selector: 'app-signup',
    templateUrl: './signup.component.html',
    styleUrls: ['./signup.component.scss'],
    providers: [PoNotificationService]
})
export class SignUpComponent implements OnInit {
    public isLoading = false;
    public userForm: FormGroup;
    public password = '';
    public confirmPassword = '';
    public isRegister = false;

    public readonly actions: Array<PoPageAction> = [
        { label: 'Voltar', action: this.goBack }
    ];

    constructor(private router: Router,
                private poNotification: PoNotificationService,
                private formBuilder: FormBuilder,
                private http: HttpService
        ) {
          this.userForm = this.formBuilder.group({
            email: ['', this.emailValidator ],
          });
    }

    ngOnInit() {
    }

    goBack() {
        this.router.navigate(['/login']);
    }

    emailValidator(control: AbstractControl) {
      const email = control.value;
      const regex = /^[a-z0-9.]+@[a-z0-9]+\.[a-z]+(\.[a-z]+)?$/i;
      if (regex.test(email) && (email.includes('@totvs') || email.includes('@totvspartners') )) {
        return null;
      }
      return { errorEmail: true };
    }
    registerUser() {
      this.isLoading = true;
      const email = this.userForm.getRawValue().email;
      const password = this.password;
      this.http.post('auth/register', {user: email, password}).subscribe( res => {
        this.isRegister = true;
        this.isLoading = false;
      }, err => {
        this.poNotification.error({message: err.error.error});
        this.isLoading = false;
      });

    }
    validPassword() {
      if (this.password === this.confirmPassword && this.password.length > 5 && this.userForm.valid) {
        return true;
      } else {
        return false;
      }
    }
}

