
<!-- <router-outlet></router-outlet> -->

<div class="po-wrapper">
  <!-- <po-toolbar p-title="AppName"></po-toolbar> -->
  <po-toolbar p-title="Applinker" [p-actions]="actions" *ngIf="app.validateHasToken()" ></po-toolbar>

  <po-menu [p-menus]="menus" *ngIf="app.validateHasToken()"></po-menu>


    <router-outlet></router-outlet>

</div>
