<po-page-login
  [p-literals]="customLiterals"
  (p-login-submit)="loginSubmit($event)"
  p-hide-remember-user="true"
  p-product-name="AppLinker"
  [p-loading]="loading"
  p-register-url="/signup"
  [p-languages]="languages"
  p-recovery="/reset-password"
></po-page-login>
