import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PoModule } from '@po-ui/ng-components';
import { SignUpRoutingModule } from './signup-routing.module';
import { SignUpComponent } from './signup.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    SignUpRoutingModule,
    PoModule,
    ReactiveFormsModule
  ],
  declarations: [
    SignUpComponent
  ],
  providers : [
  ]
})
export class SignUpModule { }
