<form [formGroup]="connectForm">
  <div class="po-row">
    <po-select class="po-md-6" name="combo" formControlName="app" p-label="Aplicativo" [p-options]="appsOptions"
      (p-change)="appSelected($event)">
    </po-select>
    <div *ngIf="isMpNotification" class="po-row card-warning">
      <div class="header-card"><b>!</b></div>
      <div class="content-card">O uso do aplicativo <b>Meu Protheus</b> por meio desta ferramenta (AppLinker) NÃO É COMPATÍVEL com a funcionalidade de NOTIFICAÇÕES. Para tal, deve ser criado um ALIAS através do Mingle (https://tdn.totvs.com/x/b_ITEQ).</div>
    </div>
  </div>

  <div *ngIf="showParams" class="po-row">
    <po-divider *ngIf="params.length"></po-divider>
    <div formArrayName="params">
      <div *ngFor="let param of connectForm.get('params').controls; let i = index;">
        <div [formGroupName]="i">
          <div class="po-row">
          <!-- <pre>{{params[i].key | json}}</pre> -->
            <po-input class="po-md-12" [formControlName]="params[i].key" [p-label]="params[i].key" name="input"></po-input>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="po-row">
    <po-button class="po-md-3"  (p-click)="connect()" p-label="Conectar" [p-disabled]="!connectForm.valid" [p-loading]="buttonLoading"></po-button>
  </div>
</form>
