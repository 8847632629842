import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable()
export class AuthInterceptorService implements HttpInterceptor {

  constructor(private router: Router) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    const idToken = localStorage.getItem('userToken');

    if (idToken) {
      if (req.headers.has('Authorization') && req.headers.get('Authorization').indexOf('Basic') >= 0) {
        return next.handle(req);
      } else {
        const cloned = req.clone({
          headers: req.headers.set('Authorization', 'Bearer ' + idToken)
        });
        return next.handle(cloned);
      }

    } else {
      return next.handle(req).pipe(tap(() => { },
        (err: any) => {
          if (err instanceof HttpErrorResponse) {
            if (err.status !== 401) {
              return false;
            }
            localStorage.removeItem('userToken');
            this.router.navigate(['login']);
          }
        }
      ));
    }
  }

}
