import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { PoPageAction } from '@po-ui/ng-components';
import { PoNotificationService} from '@po-ui/ng-components';
import { FormBuilder, Validators, AbstractControl, FormGroup } from '@angular/forms';
import { HttpService } from '../providers/http.service';


@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

  public isLoading = false;
  public userForm: FormGroup;
  public password = '';
  public confirmPassword = '';
  public isRegister = false;
  public isUpdatePassword = false;
  public token = '';

  public readonly actions: Array<PoPageAction> = [
    { label: 'Voltar', action: this.goBack }
  ];

  constructor(private router: Router,
    private poNotification: PoNotificationService,
    private formBuilder: FormBuilder,
    private http: HttpService,
    private activatedRoute: ActivatedRoute
  ) {
    this.userForm = this.formBuilder.group({
      email: ['', this.emailValidator],
    });
    this.activatedRoute.queryParams.subscribe(params => {
      if(params.token) {
        this.token = params.token;
      }
    });
  }

  ngOnInit() {
  }

  goBack() {
    this.router.navigate(['/login']);
  }

  emailValidator(control: AbstractControl) {
    const email = control.value;
    const regex = /^[a-z0-9.]+@[a-z0-9]+\.[a-z]+(\.[a-z]+)?$/i;
    if (regex.test(email) && (email.includes('@totvs') || email.includes('@totvspartners'))) {
      return null;
    }
    return { errorEmail: true };
  }

  sentEmail() {
    this.isLoading = true;
    const email = this.userForm.getRawValue().email;
    this.http.post('auth/sentEmailToReset', { user: email }).subscribe(res => {
      console.log(res);
      this.isRegister = true;
      this.isLoading = false;
    }, err => {
      this.poNotification.error({ message: err.error.error });
      this.isLoading = false;
    });
  }

  validPassword() {
    if (this.password === this.confirmPassword && this.password.length > 5 ) {
      return true;
    } else {
      return false;
    }
  }

  updatePassword(password: string) {
    this.isLoading = true;
    this.http.post('auth/resetPassword', { token: this.token, password: password }).subscribe(res => {
      console.log(res);
      this.isUpdatePassword = true;
      this.isLoading = false;
    }, err => {
      console.log(err)
      this.poNotification.error({ message: err.error.error });
      this.isLoading = false;
    });
  }

}
