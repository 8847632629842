import { Injectable } from '@angular/core';
import { Socket } from 'ngx-socket-io';
import { HttpClient, HttpHeaders, } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

const { api } = environment;



@Injectable({
  providedIn: 'root'
})
export class AppService {
  private slot;

  public slotIsConnection = new Subject<boolean>();
  public slotResponse = new Subject<any>();

  protected URL = api;
  constructor(private socket: Socket, private http: HttpClient) {



    this.socket.on('disconnect', () => {
      this.slotIsConnection.next(false);
    });


    this.socket.on('gateway', (req: { auth?: string, method?: string, endpoint?: string, payload?: string, headers: any}, fn) => {

      // const headers = new HttpHeaders({ Authorization: req.auth });
      this.http.request(
        req.method,
        `${this.URL}/${req.endpoint}`,
        { body: req.payload, headers: req.headers, observe: 'response' }).subscribe((resp) => {
          fn(resp);
        });
    });

  }

  public setDataProtheus(URL) {
    this.URL = URL;
  }

  connectToServer(): Observable<any> {
    return this.http.post(`${this.URL}/slots`, {})
      .pipe(
        map(res => {
          // tslint:disable-next-line:no-string-literal
          this.slot = res['slot'];
          // tslint:disable-next-line:no-string-literal
          this.messageFromServer(res['slot']);
        })
      );
  }

  disconnectToServer(): Observable<any> {
    return this.http.delete(`${this.URL}/slots/${this.slot}`, {})
      .pipe(
        map(res => {
          // tslint:disable-next-line:no-string-literal
          this.desconnectServer(res['slot']);
        })
      );
  }


  messageFromServer(id) {
    this.socket.on(`room${id}`, mensagem => {
      this.concatResponse(mensagem);
    });
  }

  desconnectServer(id) {
    this.socket.disconnect();
  }

  concatResponse(message) {
    this.slotResponse.next(message);
  }


  validateHasToken(): boolean {
    if (localStorage.getItem('userToken')) {
      return true;
    }
    return false;
  }

}
