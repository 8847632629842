

<form [formGroup]="connectProtheusForm">
  <div class="po-row">

    <po-input p-disabled="true" class="po-md-6" formControlName="ip" name="input" p-label="IP" >
    </po-input>
  </div>
  <div class="po-row">

    <po-number class="po-md-3" formControlName="port" name="input" p-label="Porta" p-help="Exemplo: 8099">
    </po-number>



    <po-input class="po-md-3" formControlName="url" name="input" p-label="URL" p-help="Exemplo: rest">
    </po-input>
  </div>
  <div class="po-row" class="po-md-6">

    <po-button p-label="Conectar ao Protheus" (p-click)="testConnection(connectProtheusForm.value)"
      [p-disabled]="!connectProtheusForm.valid" [p-loading]="buttonLoading">
    </po-button>
  </div>

</form>
