import { Component } from '@angular/core';

import { PoMenuItem, PoToolbarAction } from '@po-ui/ng-components';
import { Router } from '@angular/router';
import { AppService } from './app.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent {

  actions: Array<PoToolbarAction> = [
    { icon: 'po-icon-exit', label: 'Sair', action: () => this.logout() },
  ];

  readonly menus: Array<PoMenuItem> = [
    { label: 'Home', action: this.goConnect.bind(this) },
    {
      label: 'Grupos de Atendimento',
      link: 'https://tdn.totvs.com/x/Wm9KE'
    },
  ];

  constructor(private router: Router, public app: AppService) {

  }

  private goConnect() {
    this.router.navigate(['']);
  }

  private logout() {
    localStorage.removeItem('userToken');
    // tslint:disable-next-line:semicolon
    this.router.navigate(['login']);
  }

}
