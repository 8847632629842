import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';
import { PoPageLoginModule } from '@po-ui/ng-templates';
import { PoModalPasswordRecoveryModule } from '@po-ui/ng-templates';
import { environment } from '../environments/environment';
import { AppComponent } from './app.component';
import { PoModule, PoNotificationModule } from '@po-ui/ng-components';
import { RouterModule } from '@angular/router';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ConnectComponent } from './connect/connect.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { LottieAnimationViewModule } from 'ng-lottie';
import { ConnectedComponent } from './connect/connected/connected.component';
import { ConnectServerComponent } from './connect/connect-server/connect-server.component';
import { ConnectProtheusComponent } from './connect/connect-protheus/connect-protheus.component';
import { LoginComponent } from './login/login.component';
import { AppRoutingModule } from './app-routing.module';
import { AuthInterceptorService } from './auth-interceptor.service';
import { HomeComponent } from './home/home.component';
import { SignUpModule } from './signup/signup.module';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
const { api } = environment;

const config: SocketIoConfig = { url: api, options: {} };

@NgModule({
  declarations: [
    AppComponent,
    ConnectComponent,
    ConnectedComponent,
    ConnectServerComponent,
    ConnectProtheusComponent,
    LoginComponent,
    HomeComponent,
    ResetPasswordComponent
  ],
  imports: [
    BrowserModule,
    PoModule,
    HttpClientModule,
    AppRoutingModule,
    ReactiveFormsModule,
    RouterModule.forRoot([], { relativeLinkResolution: 'legacy' }),
    SocketIoModule.forRoot(config),
    LottieAnimationViewModule.forRoot(),
    FormsModule,
    PoPageLoginModule,
    PoModalPasswordRecoveryModule,
    PoNotificationModule,
    SignUpModule

  ],
  providers: [
    AuthInterceptorService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptorService,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
