import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpService } from '../providers/http.service';
import { Socket } from 'ngx-socket-io';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class ConnectService {

  constructor(private http: HttpService, private socket: Socket, private router: Router) {
    if (this.socket.ioSocket.id === undefined || this.socket.ioSocket.id === null) {
      localStorage.removeItem('userToken');
    }
  }

  connectToServer(data): Observable<any> {
    data.socket_id = this.socket.ioSocket.id;
    return this.http.post('mingle/connect', data);
  }

  appsList() {
    return this.http.get('mingle/apps');
  }

}
