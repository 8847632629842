<po-page-detail>
  <div class="page">
    <div *ngIf="!isRegister" class="step-card">
      <div class="po-page-login-header-product-name">AppLinker</div>
      <div class="po-page-login-header-welcome">Boas-vindas</div>
      <hr />
      <p>
        Para utilizar os aplicativos Protheus em uma base local, primeiro é
        necessário fazer um cadastro no AppLinker.
      </p>
      <br />
      <p>
        O AppLinker é uma ferramenta que cria uma ponte entre seu ambiente e o
        servidor de mobilidade da TOTVS MINGLE, permitindo assim que você
        utilize um ALIAS de acesso sem necessidade de ter um IP Fixo.
      </p>
      <br />
      <div class="po-row">
        <blockquote class="sample-blockquote">
          Esta ferramenta deve ser utilizada apenas em caráter de testes. O
          <strong> ALIAS </strong> liberado terá duração de apenas uma hora.
        </blockquote>
      </div>
      <form [formGroup]="userForm">
        <po-email
          formControlName="email"
          id="email"
          name="email"
          class="po-sm-12 po-md-12"
          p-label="Insira seu e-mail"
          [p-error-pattern]="'O email deve ser @totvs ou @totvspartners'"
        >
        </po-email>
      </form>
      <po-password
        class="po-sm-12 po-sm-6"
        name="newPassword"
        p-clean
        p-error-pattern="Senha invalida"
        p-label="Senha"
        [(ngModel)]="password"
        p-required
        p-minlength="6"
      >
      </po-password>

      <po-password
        class="po-sm-12 po-sm-6"
        name="confirmNewPassword"
        p-clean
        p-error-pattern="Senha invalida"
        p-label="Confirme a senha"
        [(ngModel)]="confirmPassword"
        p-required
        p-minlength="6"
      >
      </po-password>
      <div
        class="po-row error"
        *ngIf="!validPassword() && confirmPassword.length > 0"
      >
        * As senhas precisam ser iguais
      </div>

      <div class="po-row">
        <blockquote class="sample-blockquote">
          Lembre-se de não compartilhar sua senha, pois o acesso a ferramenta é
          de uso exclusivo do usuário.
        </blockquote>
      </div>
      <div class="po-row">
        <po-button
          class="po-sm-12 po-md-4 po-lg-3"
          p-label="Cadastre-se"
          [p-disabled]="!validPassword()"
          (p-click)="registerUser()"
        >
        </po-button>
      </div>
    </div>
    <div *ngIf="isRegister" class="step-card">
      <div class="title">Usuário cadastrado com sucesso :)</div>

      <p>
        Seu usuário foi cadastrado em nosso sistema, porém será necessário a
        aprovação do mesmo. Você receberá um email para ativação do seu cadastro.
      </p>
      <br />

      <blockquote class="sample-blockquote">
        A ativação acontece automanticamente, caso encontre algum problema no login entre
        em contato com o email: protheus.mobile@totvs.com.br
      </blockquote>
      <div class="footer">
        <po-button class="po-md-4" p-label="Ir para home" (p-click)="goBack()">
        </po-button>
      </div>
      <br />
    </div>
  </div>
</po-page-detail>
