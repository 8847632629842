<po-page-detail>
    <div class="page">
        <div *ngIf="!isRegister && !isUpdatePassword" class="step-card">
            <div class="po-page-login-header-product-name">AppLinker</div>
            <hr />
            <p *ngIf="!token">
               Por Favor, informe o E-mail associado a sua conta que enviaremos um link para o mesmo com as instruções para restauração da sua senha.
            </p>
            <p *ngIf="token">
                Por Favor, informe sua nova senha.
             </p>
            <br />
            <div *ngIf="!token">
                <form [formGroup]="userForm">
                    <po-email formControlName="email" id="email" name="email" class="po-sm-12 po-md-12"
                        p-label="Insira seu e-mail" [p-error-pattern]="'O email deve ser @totvs ou @totvspartners'">
                    </po-email>
                </form>
                <div class="po-row">
                    <blockquote class="sample-blockquote">
                        Esta ferramenta deve ser utilizada apenas em caráter de testes. O
                        <strong> ALIAS </strong> liberado terá duração de apenas uma hora.
                    </blockquote>
                </div>
                <div class="po-row">
                    <po-button class="po-sm-12 po-md-4 po-lg-3" p-label="Enviar" (p-click)="sentEmail()"
                        [p-disabled]="userForm.invalid">
                    </po-button>
                </div>
            </div>

            <div *ngIf="token">
                <po-password class="po-sm-12 po-sm-6" name="newPassword" p-clean p-error-pattern="Senha invalida"
                    p-label="Senha" [(ngModel)]="password" p-required p-minlength="6">
                </po-password>

                <po-password class="po-sm-12 po-sm-6" name="confirmNewPassword" p-clean p-error-pattern="Senha invalida"
                    p-label="Confirme a senha" [(ngModel)]="confirmPassword" p-required p-minlength="6">
                </po-password>
                <div class="po-row error" *ngIf="!validPassword() && confirmPassword.length > 0">
                    * As senhas precisam ser iguais
                </div>
                <div class="po-row">
                    <blockquote class="sample-blockquote">
                        Esta ferramenta deve ser utilizada apenas em caráter de testes. O
                        <strong> ALIAS </strong> liberado terá duração de apenas uma hora.
                    </blockquote>
                </div>
                <div class="po-row">
                    <po-button class="po-sm-12 po-md-4 po-lg-3" p-label="Alterar senha" (p-click)="updatePassword(password)"
                        [p-disabled]="!validPassword()">
                    </po-button>
                </div>
            </div>

        </div>

        <div *ngIf="isRegister" class="step-card">
            <div class="title">Email enviado com sucesso :)</div>
            <div class="footer">
                <po-button class="po-md-4" p-label="Ir para home" (p-click)="goBack()">
                </po-button>
            </div>
            <br />
        </div>
        <div *ngIf="isUpdatePassword" class="step-card">
            <div class="title">Senha alterada com sucesso :)</div>
            <div class="footer">
                <po-button class="po-md-4" p-label="Ir para home" (p-click)="goBack()">
                </po-button>
            </div>
            <br />
        </div>
    </div>
</po-page-detail>