import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, CanActivate, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AppService } from './app.service';

@Injectable({
  providedIn: 'root'
})
export class AppGuard implements CanActivate {

  constructor(private router: Router, private app: AppService) {
  }

  canActivate(): boolean {
    if (this.app.validateHasToken()) {
      return true;
    }

    this.router.navigate(['/login']);
    return false;
  }
}
